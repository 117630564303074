.rubric {
	&__grid {
		display: grid;
		grid-template-columns: 1fr 210px;
		grid-template-rows: mincontent;
		align-items: flex-start;
		grid-column-gap: 50px;

		@media (max-width: 768px) {
			grid-column-gap: 30px;
			grid-template-columns: 1fr auto;
		}
		@media (max-width: 480px) {
			grid-template-columns: 1fr;
		}
	}
	&__img {
		grid-column: 2/3;
		grid-row: 1/3;
		margin-left: auto;

		@media (max-width: 480px) {
			grid-column: auto;
			grid-row: auto;
			margin: 15px auto;
		}
	}
	&__text {
		max-width: 1035px;
	}
	&__list {
		grid-column: 1/-1;
		display: grid;
		grid-template-columns: auto auto auto auto;
		grid-gap: 30px;
		justify-content: space-between;
		align-items: center;
		margin-top: 50px;
		margin-bottom: 60px;
		padding: 0;
		text-align: center;
		list-style: none;

		@media (max-width: 768px) {
			margin-top: 20px;
			margin-bottom: 30px;
		}
		@media (max-width: 600px) {
			grid-gap: 15px;
			grid-template-columns: 1fr 1fr;
		}
		&--item6 {
			grid-template-columns: repeat(6, auto);

			@media (max-width: 940px) {
				grid-template-columns: repeat(3, auto);
			}
		}
		&--item5 {
			grid-template-columns: repeat(5, auto);

			@media (max-width: 520px) {
				grid-template-columns: repeat(3, auto);
			}
		}
	}
	&__btn {
		grid-column: 1/-1;
		display: block;
		width: 200px;
		margin: 0 auto;
		padding: 13px;
		text-align: center;
		color: #fff;
		background-color: #982020;
		border-radius: 5px;
		transition: background-color 0.2s;

		&:hover {
			background-color: $red;
		}
		& + p {
			margin-top: 60px;

			@media (max-width: 768px) {
				margin-top: 30px;
			}
		}
	}
	&__btn--big {
		width: 100%;
		max-width: 255px;
	}
	&__block {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		row-gap: 15px;
		margin-top: 25px;

		img {
			width: 315px;

			@media (max-width: 600px) {
				order: -1;
				width: auto;
				margin: 0 auto;
			}
		}
		.rubric__block_text {
			width: calc(100% - 455px);

			@media (max-width: 900px) {
				width: calc(100% - 350px);
			}
			@media (max-width: 600px) {
				width: 100%;
			}
			& .rubric__btn {
				margin: 20px 0 0 auto;
			}
		}
		@media (min-width: 769px) {
			& + .rubric__block {
				margin-top: 40px;
			}
		}
	}
	&_slider {
		position: relative;
		grid-column: 1/-1;
		margin-top: 10px;
		margin-bottom: 30px;

		.swiper-slide {
			height: auto;
		}
		.swiper-button-prev {
			left: 0px;
		}
		.swiper-button-next {
			right: 0px;
		}
		li {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
		.catalog-list-item {
			height: 100%;
		}		
		.catalog-list-item__link {
			grid-row: initial;
		}
	}
	&_slider--item {
		width: 100%;
		max-width: 1300px;
		margin: 0 auto 50px;
		@media (min-width: 1360px) {
			.swiper-button-prev {
				left: -25px;
			}
			.swiper-button-next {
				right: -25px;
			}
		}
		@media (max-width: 768px) {
			margin-bottom: 30px;
		}
	}
}
.entrance_top {
	padding-top: 70px;
	padding-bottom: 75px;
	background-color: #fff;

	@media (max-width: 1199px) {
		padding-top: 50px;
	}
	@media (max-width: 768px) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
}
.entrance_rubric {
	padding-top: 35px;
	padding-bottom: 70px;

	@media (max-width: 768px) {
		padding-top: 20px;
		padding-bottom: 30px;
	}

	h2, .h2 {
		padding-bottom: 20px;
		text-align: left;
		line-height: 1.1;
	}
	&--white {
		padding-top: 30px;
		background-color: #fff;

		@media (max-width: 768px) {
			padding-top: 20px;
		}
	}
	&--white2 {
		background-color: #fff;

		p + .rubric__btn {
			margin-top: 65px;

			@media (max-width: 768px) {
				margin-top: 30px;
			}
		}
	}
	&--gray {
		grid-template-rows: auto auto 1fr;
		padding-top: 65px;

		@media (max-width: 1199px) {
			padding-top: 35px;
		}
		@media (max-width: 768px) {
			padding-top: 20px;
		}

		h2, .h2 {
			padding-top: 5px;
		}
		@media (min-width: 481px) {
			.rubric__img {
				grid-row: 1/4;
			}
		}
		.rubric__text {
			color: #464646;
		}
		.rubric__btn {
			@media (min-width: 681px) {
				margin-top: -65px;
			}
			@media (max-width: 680px) {
				margin-top: 30px;
			}
		}
	}
	&--interiordoors {
		grid-template-columns: 1fr auto;
		padding-top: 85px;

		@media (max-width: 1199px) {
			padding-top: 35px;
		}
		@media (max-width: 768px) {
			grid-template-columns: 1fr 0.5fr;
			padding-top: 25px;
		}
		@media (max-width: 600px) {
			grid-template-columns: 1fr;

			.rubric__img {
			    grid-column: auto;
			    grid-row: auto;
			    margin: 15px auto;
			}
		}

		h2, .h2 {
			padding-top: 0;
		}
		.rubric__text {
			max-width: 895px;
			color: #464646;
		}
	}
	&--partition {
		h2 {
			text-align: center;
		}
		.rubric__list {
			margin-bottom: 0;
		}
	}
	&--partition1 {
		padding-top: 85px;
		background-color: #fff;

		@media (max-width: 1199px) {
			padding-top: 35px;
		}
		@media (max-width: 768px) {
			padding-top: 25px;
		}
		.rubric__grid {
			grid-template-columns: 1fr auto;

			@media (max-width: 768px) {
				grid-template-columns: 1fr 0.5fr;
			}
			@media (max-width: 600px) {
				grid-template-columns: 1fr;

				.rubric__img {
				    grid-column: auto;
				    grid-row: auto;
				    margin: 15px auto;
				}
			}
		}

		h2, .h2 {
			padding-top: 0;
		}
		.rubric__text {
			max-width: 920px;

			&_img {
				padding: 25px 0 20px;
			}
			@media (max-width: 600px) {
				img {
					display: block;
					margin: 0 auto;
				}
			}
		}
		.rubric__btn {
			margin-top: 35px;
		}
	}
	&--partition3 {
		.interiordoors_list {
			margin: 35px 0 40px;

			@media (max-width: 768px) {
				margin: 20px 0;
			}

			li {
				min-height: 195px;
				padding-left: 165px;
				background-color: $gray;
				background-image: url(/assets/images/svg/peregorodki1.svg);
			    background-position: 50px center;
			    background-repeat: no-repeat;
			    background-size: 75px;

			    &:nth-child(2) {
			    	background-image: url(/assets/images/svg/peregorodki2.svg);
			    	background-size: 75px;
			    	background-position: 50px center;
			    }
			    @media (max-width: 480px) {
			    	min-height: 150px;
					padding-left: 110px;
					background-position: 15px center;

					&:nth-child(2) {
						background-position: 15px center;
					}
				}
			}
		}
		.center {
			padding: 25px 0 70px;

			@media (max-width: 768px) {
				padding-bottom: 30px;
			}	
		}
	}
	&--partition4 {
		padding-bottom: 0;
	}
	& .rubric_slider  {
		background-color: #fff;
	}
}
.entrance_text {
	padding-top: 90px;
	padding-bottom: 100px;

	@media (max-width: 768px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	p {
		padding: 45px;
		font-size: 22px;
		border-radius: 37px;
		background-color: #fff;

		@media (max-width: 520px) {
			padding: 25px;
			font-size: 18px;
		}

		span {
			color: $red;
		}
		a {
			color: #101e99;
		}
	}
}
.interiordoors_list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px 130px;
	margin: 40px 0 60px;
	padding: 0;
	list-style: none;

	@media (max-width: 1199px) {
		grid-gap: 30px;
	}
	@media (max-width: 768px) {
		margin: 30px 0;
	}
	@media (max-width: 720px) {
		grid-template-columns: 1fr;
	}

	li {
		display: flex;
		align-items: center;
		padding: 35px 45px 35px 210px;
		background-color: #fff;
		border-radius: 20px;
		background-image: url(/assets/images/svg/dver1.svg);
		background-position: 20px center;
		background-repeat: no-repeat;
		background-size: 150px;

		@media (max-width: 900px) {
			min-height: 190px;
			padding: 20px 20px 20px 150px;
			background-position: left center;
			background-size: 120px;
		}

		&:nth-child(2) {
			background-image: url(/assets/images/svg/dver2.svg);
			background-position: 47px center;
			background-size: 113px;

			@media (max-width: 900px) {
				background-position: 27px center;
				background-size: 95px;
			}
		}
	
	}
}
.partition {
	&_scheme_list {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 90px;
		margin: 50px 0 55px;
		padding: 0;
		text-align: center;
		list-style: none;

		@media (max-width: 1199px) {
			grid-row-gap: 60px;
		}
		@media (min-width: 769px) {
			grid-template-areas: "a a b b c c"
	                         "d d d e e e";

			li:nth-child(1) {
				 grid-area: a;
			}
			li:nth-child(2) {
				 grid-area: b;
			}
			li:nth-child(3) {
				 grid-area: c;
			}
			li:nth-child(4) {
				 grid-area: d;
			}
			li:nth-child(5) {
				grid-area: e;
			}
		}
		@media (max-width: 768px) {
			grid-row-gap: 40px;
			grid-template-columns: repeat(2, 1fr);
			margin: 20px 0 40px;

			li:nth-child(5) {
				grid-column: 1/-1;
			}
		}
		@media (max-width: 400px) {
			grid-template-columns: 1fr;
		}
		li {
			margin: 0 auto;
		}
		img {
			display: block;
			margin-bottom: 20px;

			@media (max-width: 480px) {
				margin-bottom: 15px;
			}
		}
	}
	&_slider {
		position: relative;
		margin: 40px 0 70px;

		@media (max-width: 768px) {
			margin: 20px 0 30px;
		}
		.swiper-button-prev {
			left: -75px;

			@media screen and (max-width: 1560px) {
				left: -40px;
			}
			@media screen and (max-width: 1480px) {
				left: -15px;
			}
		}
		.swiper-button-next {
			right: -75px;

			@media screen and (max-width: 1560px) {
				right: -40px;
			}
			@media screen and (max-width: 1480px) {
				right: -15px;
			}
		}
		img {
			display: block;
			margin: 0 auto;
		}
	}
	&_list {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: 90px;
		grid-row-gap: 40px;
		justify-content: space-between;
		margin: 25px 0 55px;
		padding: 0;
		text-align: center;
		line-height: 0;
		list-style: none;

		@media (max-width: 1199px) {
			grid-column-gap: 30px;
		}
		@media (max-width: 980px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (max-width: 768px) {
			grid-gap: 20px;
			margin-bottom: 30px;
		}
		@media (max-width: 520px) {
			grid-template-columns: repeat(3, 1fr);
		}

		p {
			padding: 20px 25px 0;
			line-height: 1.2;

			@media (max-width: 1199px) {
				padding: 20px 0 0;
			}
		}
	}
}
.rubric_links {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	column-gap: 50px;
	row-gap: 15px;
	margin: 42px 0 38px;

	&--interiordoors {
		margin-bottom: -18px;
	}
	&--catalog {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 20px;
		row-gap: 30px;
		margin: 0 0 60px;
	}

	@media (max-width: 1080px) {
		column-gap: 15px;

		&--catalog {
			grid-template-columns: repeat(3, 1fr);
			row-gap: 20px;
		}
	}	
	@media (max-width: 768px) {
		margin: 25px 0;

		&--interiordoors {
			margin-bottom: 0;
		}
		&--catalog {
			row-gap: 15px;
			margin: 0 0 30px;
		}
	}	
	@media (max-width: 480px) {
		&--catalog {
			column-gap: 10px;
		}
	}	
	@media (max-width: 420px) {
		&--catalog {
			grid-template-columns: repeat(2, 1fr);
		}
	}	

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 10px 16.5px;
		color: #fff;
		text-align: center;
		font-size: 16px;
		line-height: 1.1;
		background-color: $red;
		border-radius: 5px;
		transition: background-color 0.2s;

		&:hover {
			background-color: #982020;
		}
		@media (max-width: 768px) {
			padding: 10px 13px;
			font-size: 14px;
		}	
		&--catalog {
			padding: 14px 0 0 14px;
			border-radius: 0;
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: calc(100% - 14px);
				height: calc(100% - 14px);
				background-color: #fff;
				border: 4px solid #834343;
			}
			.rubric_links__item_name {
				position: relative;
				z-index: 1;
				margin-top: auto;
				padding: 6px 3px;
				color: #000;
				font-weight: 700;
				line-height: 1.3;
				font-size: 14px;
				background-color: #e0dbd9;
			}
			img {
				position: relative;
				z-index: 1;
				border: 3px solid #e0dbd9;

				& + .rubric_links__item_name {
					padding-top: 3px;
				}
			}
		}
	}

}
.entrance_contacts {
	padding-top: 20px;

	.entrance_map {
		height: 400px;
		
		.ymaps-2-1-79-hint {
			box-shadow: none;
			&__text {
				background: none;
			}
		}
		.map-img {
			width: 248px;
			padding: 3px;
			font-size: 0;
			line-height: 0;
			border-radius: 15px;
			border: none;
			background-color: rgb(255, 255, 255);
			box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.5);
			box-sizing: border-box;
	
			img {
				border-radius: 15px;
			}
		}
	}
}
.entrance_setup {
	background-color: #fff;
	padding-top: 20px;
	padding-bottom: 30px;

	& .h2 {
		padding: 25px 0;
	}
	& p {
		padding: 0;
		margin: 0 0 20px;
		line-height: 1.2em;
	}

	& .entrance_setup__list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		row-gap: 60px;
		padding: 30px 0;
		margin: 0 0 0 -35px;
		list-style: none;

		& li {
			width: calc((100% / 3) - 35px);
			margin-left: 35px;
		}
	}
	& .entrance_setup__img {
		display: block;
		margin-bottom: 15px;
		& img {
			display: block;
		}
	}
	& .entrance_setup__sub {
		display: block;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	@media (max-width: 1199px) {
		& .entrance_setup__list {
			row-gap: 40px;
		}
		& .entrance_setup__sub {
			font-size: 22px;
		}
	}
	@media (max-width: 992px) {
		& .entrance_setup__list {
			justify-content: space-between;
			margin-left: 0;
			& li {
				width: 48.5%;
				margin-left: 0;
			}
		}
		& .entrance_setup__sub {
			font-size: 20px;
		}
	}
	@media (max-width: 600px) {
		& .entrance_setup__list {
			padding: 0;
			row-gap: 30px;
			& li {
				width: 100%;
			}
			& p {
				margin-bottom: 15px;
			}
		}
		& .entrance_setup__img img {
			margin: 0 auto;
		}
	}
}
.partition_price {
	margin-bottom: 100px;
	& h2 {
		text-align: left;		
	}	
}
.partition_price__row{
	align-items: flex-start;
	margin-bottom: 20px;
}
.partition_price__img {
	width: auto;
	flex-shrink: 0;
	margin-right: 35px;
}
.partition_price__text {
	flex: 1;
	& p {
		padding: 0;
		margin: 0 0 10px;
		line-height: 1.2em;
	}
}
.partition_price__text + .partition_price__img {
	margin-left: 35px;
	margin-right: 0;
}
.partition_number {
	position: relative;
	padding-left: 38px;

	&::before {
		content: attr(data-num);
		position: absolute;
		left: 0;
		top: 0;
		font-size: 36px;
		color: #e1ceb7;
		font-weight: 900;
		line-height: 1em;
	}
	& ul {
		padding: 10px 0;
		margin: 0;
		list-style: none;
		line-height: 1.2em;
	}
	& li {
		position: relative;
		margin-bottom: 10px;
		&::before {
			content: "";
			position: absolute;
			width: 16px;
			height: 8px;
			left: -22px;
			top: 5px;
			background-color: #e1ceb7;
		}
	}
}
.partition_products {
	margin-bottom: 100px;
}
@media (max-width: 992px) {
	.partition_price,
	.partition_products {
		margin-bottom: 50px;
	}
	.partition_price__img {
		width: 30%;
	}
}
@media (max-width: 768px) {
	.partition_price__row {
		margin-bottom: 0;
	}
	.partition_price__img {
		text-align: center;
		width: 100%;
		margin: 0 auto 20px;
	}
	.partition_price__text {
		width: 100%;
		flex: initial;
		& + .partition_price__img {
			margin: 20px auto 0;
		}
	}
}
@media (max-width: 480px) {
	.partition_number {
		padding-left: 28px;
		&::before {
			font-size: 30px;
		}
		& li::before {
			width: 12px;
			height: 6px;
		}
	}
}