.catalog {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 50px;
  align-items: flex-start;
  padding-bottom: 60px;

  @media (max-width: 1240px) {
    grid-template-columns: 280px 1fr;
    grid-column-gap: 30px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
  .breadcrumbs {
    grid-column: 1/-1;
  }
  h1 {
    margin-bottom: 5px;
  }
  @media (min-width: 769px) {
    h1 {
      grid-column: 2/3;
      grid-row: 2/3;
      text-align: left;
    }
    &__content {
      grid-column: 2/3;
      grid-row: 3/4;
    }
  }
  &__btn {
    display: block;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    padding: 10px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    border: none;
    border-radius: 5px;
    background-color: #691b1b;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;

    @media (min-width: 769px) {
      display: none;
    }

    &.active {
      color: $red;
      background-color: #c6bbb8;
    }
  }
  &__text {
    padding-bottom: 60px;
    // grid-column: 1/-1;
  }
}
aside {
  @media (min-width: 769px) {
    grid-row: 3/4;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .arrow {
    position: relative;
    top: -2px;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 11px;
    border-bottom: 3px solid #691b1b;
    border-right: 3px solid #691b1b;
    transform: rotate(45deg);
    transition: 0.15s;
  }
  .open > .arrow {
    top: 3px;
    transform: rotate(225deg);
  }
  > ul {
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-count: 20px;
      margin-top: 20px;
    }
    @media (max-width: 460px) {
      grid-template-columns: 1fr;
    }
    > li {
      position: relative;
      margin-bottom: 22px;

      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
      > a {
        display: block;
        font-size: 18px;
        font-weight: 600;

        &:hover {
          color: #982020;
          text-decoration: underline;
        }
      }
      & {
        > a.active {
          &:last-child {
            margin-left: -15px;
            padding: 6px 15px;
            color: #fff;
            background-color: $red;
          }
        }
        .open + ul {
          display: block;
        }
      }
      > ul {
        display: none;
        padding: 10px 0 0;

        > li {
          margin-bottom: 12px;
          padding-left: 17px;

          > a {
            display: block;
            max-width: 220px;
            font-size: 17px;
            font-weight: 500;
            color: #202020;
          }
          & > a.active {
            &:last-child {
              padding: 6px 15px;
              color: #fff;
              background-color: $red;
            }
          }
          .open + ul {
            display: block;
          }
          > a:hover {
            color: #982020;
            text-decoration: underline;
          }
          > ul {
            display: none;
            padding: 5px 0;

            &.open {
              display: block;
            }

            .arrow {
              width: 9px;
              height: 9px;
              border-bottom: 3px solid #691b1b;
              border-right: 3px solid #691b1b;
            }

            li {
              margin-bottom: 2px;

              & > a.active {
                display: inline-block;
                text-decoration: underline;
                background-color: #e0dbd9;
                color: #691b1b;
              }
              .open + ul {
                display: block;
              }
              > ul {
                display: none;
                padding: 0;

                > li {
                  padding-left: 18px;

                  & > a.active {
                    color: #fff;
                    background-color: $red;
                  }
                }
              }
            }
            a {
              display: block;
              max-width: 220px;
              padding: 6px 18px;
              font-size: 13px;
              color: #060606;

              &:hover {
                color: #982020;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  & .search {
    max-width: 275px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      margin-bottom: 20px;
    }

    & input {
      padding: 5px 15px;
      margin: 0;
    }
    & .search__btn {
      width: 44px;
      height: 44px;
      background-size: 20px;
    }
  }
}
.catalog-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin: 0 0 45px;
  padding: 0;
  list-style: none;

  @media (max-width: 1200px) {
    grid-gap: 20px 15px;
  }
  @media (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 860px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }
  @media (max-width: 420px) {
    grid-template-columns: 100%;
  }
  li {
    display: flex;
    flex-direction: column;
    display: grid;
    grid-row: span 5;
    grid-template-rows: subgrid;
    grid-gap: 0;

    &.empty {
      grid-column: 1/-1;
    }
  }
}
.catalog-list-item {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  display: grid;
  grid-row: span 5;
  grid-template-rows: subgrid;
  grid-gap: 0;
  padding: 15px;
  background-color: #fff;

  &__photo {
    margin-bottom: 22px;
    text-align: center;
    line-height: 0;

    @media (max-width: 480px) {
      margin-bottom: 15px;
    }
    img {
      max-height: 270px;
    }
  }
  &__name {
    // min-height: 40px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 420px) {
      min-height: 0;
    }
  }
  &__price {
    margin-bottom: 15px;
    font-size: 13px;

    &_old {
      text-decoration: line-through;
    }
  }
  &__color {
    display: flex;
    column-gap: 7px;
    margin-bottom: 30px;

    @media (max-width: 480px) {
      margin-bottom: 15px;
    }
    &--empty {
      margin-bottom: 0;
    }
    span {
      align-self: center;
      font-size: 10px;
      color: #656060;

      &.catalog-list-item__color-img {
        width: 18px;
        height: 18px;
        border-radius: 5px;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid #691b1b;
        &:hover {
          border-color: #e0dbd9;
        }

        img {
          max-width: none;
          object-fit: none;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &__link {
    width: 100%;
    max-width: 139px;
    margin-top: auto;
    padding: 10px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    background-color: $red;
    transition: all 0.2s;
    grid-row: -1;

    &:hover {
      color: $red;
      background-color: #c6bbb8;
    }
  }
  &__billets {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 7px;
    top: 12px;
    right: 15px;
    z-index: 10;
  }
  &__billet {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 26px;
    border: 1px solid $red;
    background-color: #fff;
    color: $red;
    font-size: 13px;
    border-radius: 3px;
    font-weight: 600;
  }
  &__billet::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
  }
  &__new {
    padding: 2px 5px 2px 28px;
  }
  &__new::before {
    width: 19px;
    background: url("/assets/images/logo-new-red.png") center center / 100%
      no-repeat;
  }
  &__termo {
    padding: 2px 5px 2px 18px;
  }
  &__termo::before {
    width: 11px;
    background: url("/assets/images/logo-termo-red.png") center center / 100%
      no-repeat;
  }
  &__lock {
    padding: 2px 5px 2px 30px;
    max-width: 91px;
    line-height: 0.8em;
  }
  &__lock::before {
    width: 16px;
    height: 20px;
    background: url("/assets/images/logo-lock-red.png") center center / 100%
      no-repeat;
  }
}
.item_page {
  padding-bottom: 90px;
  @media (max-width: 768px) {
    padding-bottom: 40px;
  }
}
.item {
  display: block;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
  &__back {
    display: inline-block;
    margin: 25px 0 0;
    padding: 10px 30px 10px 65px;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    background-color: #fff;
    background-image: url(/assets/images/svg/arrow_right.svg);
    background-position: 25px center;
    background-repeat: no-repeat;
    background-size: 22px;
    border-radius: 5px;
    border-bottom: none;
    border: none;
    cursor: pointer;
    transition: 0.2s;

    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
    &:hover {
      color: $red;
      background-color: #c6bbb8;
    }
  }
  h1 {
    text-align: left;
  }
  &__content {
    display: flex;
    column-gap: 70px;
    row-gap: 20px;
    align-items: flex-start;
    grid-template-columns: 450px 1fr;

    .item__slider {
      width: 450px;
    }
    .item__info {
      width: calc(100% - 450px);
    }
    &--colors {
      .item__slider {
        width: calc(100% - 540px);
        max-width: 715px;

        @media (max-width: 1199px) {
          width: calc(100% - 350px);
        }
        .slider-for .swiper-slide {
          background-color: #f5f5f5 !important;
        }
      }
      .item__info {
        width: 540px;

        @media (max-width: 1199px) {
          width: 350px;
        }
      }
    }
    @media (max-width: 1199px) {
      column-gap: 30px;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;

      .item__slider,
      .item__info {
        width: 100%;
      }
    }
  }
  .item__slider {
    position: relative;
    display: flex;
    column-gap: 40px;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .swiper {
      width: 100%;
    }
    .slider-for {
      width: 100%;

      &--flex {
        display: grid;
        grid-template-columns: calc(50% - 15px) calc(50% - 15px);
        grid-column-gap: 30px;

        > img {
          margin-left: auto;
        }
      }
      .swiper-slide {
        text-align: center;
        line-height: 0;
        background-color: #fff;
      }
    }
    .slider-nav {
      width: 90px;
      height: 596px;

      @media (max-width: 600px) {
        width: 100%;
        height: auto;
        margin-top: 20px;
        order: 2;
      }

      .swiper {
        height: 100%;
      }

      & + .slider-for {
        width: calc(100% - 130px);

        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .swiper-slide {
        text-align: center;
        line-height: 0;
        background-color: #fff;
        overflow: hidden;
      }
    }
  }
  .item__price {
    padding: 0 0 15px;
    font-size: 32px;

    @media (max-width: 768px) {
      padding-bottom: 10px;
    }
    &_old {
      text-decoration: line-through;
    }
  }
  .item__lock {
	&_sub {
		display: block;
		margin-bottom: 10px;
		font-size: 18px;
	}
    &_billet {
      background-color: #ebe8e7;
      width: 100%;
      max-width: 415px;
	  padding: 10px;
      border-radius: 5px;
    }
    &_list {
      font-size: 11px;
      padding: 0;
	  width: 100%;
	  max-width: 300px;
      margin: 0 auto;
      list-style: none;
      display: flex;
      justify-content: space-between;
    }
	&_item {
		position: relative;
		padding-top: 65px;
		text-align: center;
		width: 31.5%;
		line-height: 1em;
	}
	&_item::before {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		width: 65px;
		height: 62px;
	}
	&_item--fingerprint::before {
		background: url('/assets/images/item_lock_fingerprint.png') center center / 100% no-repeat;
	}
	&_item--app::before {
		background: url('/assets/images/item_lock_app.png') center center / 100% no-repeat;
	}
	&_item--key::before {
		background: url('/assets/images/item_lock_key.png') center center / 100% no-repeat;
	}
  }
  .item__text {
    padding-top: 20px;
    p {
      padding: 0 0 10px;
    }
  }
  .item__color {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 25px 0 35px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    &-item {
      position: relative;
      width: 60px;
      height: 60px;
      line-height: 0;
      border-radius: 5px;
      background-color: #fff;
      cursor: pointer;
      transition: 0.2s;
      overflow: hidden;
      border: 1px solid #691b1b;
      &:hover {
        border-color: #e0dbd9;
      }

      @media (max-width: 480px) {
        width: 45px;
        height: 45px;
      }

      &.active::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 5px solid #fff;
        border-radius: 5px;
        z-index: 1;
      }
      &.active::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 1px solid rgb(112, 112, 112);
        border-radius: 5px;
        z-index: 2;
      }
      img {
        max-width: none;
        object-fit: none;
        transform: translate(-50%, -50%);
      }
    }
  }
  .item__btns {
    display: flex;
    column-gap: 14px;
    row-gap: 10px;
    flex-wrap: wrap;
    margin: 35px 0 20px;
  }
  .item__btn {
    display: block;
    width: 100%;
    max-width: 220px;
    padding: 13px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    border: none;
    border-radius: 5px;
    background-color: $red;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: #0e0d0d;
      background-color: #e0dbd9;
    }
  }
  .item__btn--inverse {
    color: #0e0d0d;
    background-color: #e0dbd9;

    &:hover {
      color: #fff;
      background-color: $red;
    }
  }
  .item__tel {
    max-width: 585px;
    padding: 0;
    font-size: 14px;

    .phones-row {
      display: inline-flex;
      column-gap: 3px;
      margin-right: 3px;
    }
    a {
      color: #691b1b;
    }
  }
  .item__desc {
    padding: 70px 0 0;

    @media (max-width: 768px) {
      padding-top: 40px;
    }
    @media (max-width: 600px) {
      padding-top: 30px;
    }
    table {
      max-width: 100%;

      @media (max-width: 380px) {
        padding-top: 30px;

        * {
          font-size: 10px !important;
        }
      }
    }
  }
  .item__title {
    padding: 0 0 25px;
    font-size: 32px;
    line-height: 1.1;

    @media (max-width: 600px) {
      padding-bottom: 15px;
      font-size: 25px;
    }
  }
}
